import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui';
import scroll from 'vue-seamless-scroll';
import 'element-ui/lib/theme-chalk/index.css';


Vue.use(Element);
Vue.use(scroll);
Vue.config.productionTip = false;
import { asyncPost,asyncGet,asyncDelete,asyncPUT} from '@/api/async'
Vue.prototype.asyncPost=asyncPost
// Vue.prototype.asyncGet=asyncGet
// Vue.prototype.asyncDelete=asyncDelete
// Vue.prototype.asyncPUT=asyncPUT

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
