import axios from 'axios'
let baseUrl = 'https://blog.cityligi.com:443/gateway?';
//Post请求
export function asyncPost (url, params) {
  params.timeStamp=new Date().getTime()
  let userInfo = JSON.parse(localStorage.getItem("myUserInfo"));
  let urls;
  if(userInfo){
		urls = baseUrl+url+"&sessionId=" + userInfo.sessionId + "&userId=" + userInfo.userId
  }else{
		urls = baseUrl+url
  }
  return axios.post(urls, params)
}
export function asyncGet (url, params,user) {
    return axios.get(baseUrl+url, {
        params: params
    })
}
export function asyncDelete (url, params,user) {
    return axios.delete(baseUrl+url, {
        params: params
    })
}

export function asyncPUT (url, params,user) {
  params.timeStamp=new Date().getTime()
  return axios.put(baseUrl+url, params)
}

